import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import ymapsTouchScroll from 'ymaps-touch-scroll';
import { useRef } from 'react';

import s from './Maps.module.css';

const Maps = () => {
    const coordinates = [57.111898, 65.226062];
    const ref = useRef(null)
    return (
        <section className={s.maps} ref={ref}>
            <YMaps query={{ lang: "en_RU" }}>
                <Map
                    defaultState={{ center: coordinates, zoom: 9 }}
                    instanceRef={(ref) => {
                      ref && ymapsTouchScroll(ref)
                    }}
                    width="100%"
                    height="100%"
                >
                    <Placemark
                        geometry={coordinates}
                        options={{
                            preset: "islands#icon",
                            iconColor: "#0095b6",
                        }}
                        properties={{
                            iconCaption: "Улица Пятницкая, 16, Перевалово",
                        }}
                    />
                </Map>
            </YMaps>
        </section>
    );
};

export default Maps;
