import { useState, useEffect } from 'react';

const useResize = () => {
  const SCREEN_LG = 768;

  const [width, setWidth] = useState(1920);
  useEffect(() => {
    const handleResize = (event) => {
      setWidth(event.target.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    width,
    isScreenLg: width >= SCREEN_LG,
  }
}

export default useResize;
