import bg1 from "./web/01.webp";
import bg2 from "./web/02.webp";
import bg5 from "./web/05.webp";
import bg7 from "./web/07.webp";
import bg8 from "./web/08.webp";
import bg11 from "./web/11.webp";
import bg12 from "./web/12.webp";
import bg13 from "./web/13.webp";
import bg16 from "./web/16.webp";
import bg17 from "./web/17.webp";
import bg20 from "./web/20.webp";
import bg21 from "./web/21.webp";
import bg23 from "./web/23.webp";
import bg24 from "./web/24.webp";
import bg25 from "./web/25.webp";
import bg26 from "./web/26.webp";
import bg34 from "./web/34.webp";
import bg38 from "./web/38.webp";
import bg39 from "./web/39.webp";
import bg41 from "./web/41.webp";
import bg46 from "./web/46.webp";
import bg49 from "./web/49.webp";
import bg50 from "./web/50.webp";
import bg51 from "./web/51.webp";
import bg52 from "./web/52.webp";
import bg55 from "./web/55.webp";
import bg54 from "./web/54.webp";
import bg57 from "./web/57.webp";

export const heroImages = [bg1, bg2, bg23, bg46, bg49];
export const nightImages = [bg46, bg49, bg50, bg54, bg55, bg57];
export const dayImages = [bg2, bg11, bg16, bg17, bg20, bg21, bg23, bg38];
export const scrollImages = [
  bg20,
  bg21,
  bg13,
  bg8,
  bg5,
  bg26,
  bg7,
  bg12,
  bg52,
  bg46,
  bg51,
  bg39,
  bg41,
  bg24,
  bg25,
  bg54
];
export const infoImages = [
  {
    imgSrc: bg1,
    text: "Уличное пространство",
    description: [
      { subtext: "зона барбекю" },
      { subtext: "бассейн, горячий чан" },
      { subtext: "мягкая зона с экраном" },
      { subtext: "баскетбольное кольцо" },
      { subtext: "зона отдыха с качелью с видом на лес" }
    ]
  },
  {
    imgSrc: bg23,
    text: "Кухонное пространство",
    description: [{ subtext: "вместимость 25 человек" }, { subtext: "камин" }]
  },
  {
    imgSrc: bg34,
    text: "Баня на дровах",
    description: [{ subtext: "Дополнительные опции к SPA процедурам" }]
  },
  {
    imgSrc: bg39,
    text: "Спальная зона",
    description: [
      { subtext: "двуспальная кровать" },
      { subtext: "телевизор" },
      { subtext: "здоровый сон, гармония, ароматерапия" }
    ]
  }
];
