import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapMarker,
  faMobileAlt,
  faFile,
  faWarehouse,
  faHandshake
} from "@fortawesome/free-solid-svg-icons";
import VK from "./img/vk.svg";
import YouTube from "./img/youtube.svg";
import Logo from "./img/logo.png";
import Insta from "../Header/img/instagram.svg";

import s from "./Footer.module.css";

const Footer = () => {
  return (
    <footer id="contacts" className={s.footer}>
      <div className={s.wrapper}>
        <div className={s.info}>
          <h3 className={s.title}>Контакты</h3>
          <a href="tel:+79199280707" className={s.contacts} rel="noreferrer">
            <FontAwesomeIcon
              width={35}
              height={35}
              icon={faMobileAlt}
              style={{ color: "#ef4444" }}
            />
            <span>+7 (919) 928-07-07</span>
          </a>
          <a
            href="mailto:dom_otpuska72@mail.ru"
            className={s.contacts}
            rel="noreferrer"
          >
            <FontAwesomeIcon
              width={35}
              height={35}
              icon={faEnvelope}
              style={{ color: "#ef4444" }}
            />
            <span>dom_otpuska72@mail.ru</span>
          </a>
          <p className={s.contacts}>
            <FontAwesomeIcon
              width={35}
              height={35}
              icon={faMapMarker}
              style={{ color: "#ef4444" }}
            />
            <span>г. Тюмень, Перевалово, ул. Пятницкая, 16</span>
          </p>
        </div>
        <ul className={s.docs}>
          <h3 className={s.title}>Документы</h3>
          <li className={s.contacts}>
            <FontAwesomeIcon
              width={35}
              height={35}
              icon={faWarehouse}
              style={{ color: "#ef4444" }}
            />
            <Link to="/rules">Правила пребывания на территории</Link>
          </li>
          <li className={s.contacts}>
            <FontAwesomeIcon
              width={35}
              height={35}
              icon={faHandshake}
              style={{ color: "#ef4444" }}
            />
            <Link to="/terms">Правила использования</Link>
          </li>
          <li className={s.contacts}>
            <FontAwesomeIcon
              width={35}
              height={35}
              icon={faFile}
              style={{ color: "#ef4444" }}
            />
            <Link to="/rental">Договор найма</Link>
          </li>
        </ul>
      </div>
      <div className={s.social}>
        <a
          href={"https://mrr-72.ru/"}
          target="_blank"
          rel="noreferrer"
          className={s.social_link}
        >
          <img
            src={Logo}
            width={35}
            height={35}
            alt="logo"
            className={s.social_img}
          />
        </a>
        <a
          href={"https://vk.com/mrr72.stroy"}
          target="_blank"
          rel="noreferrer"
          className={s.social_link}
        >
          <img
            src={VK}
            width={35}
            height={35}
            alt="VK"
            className={s.social_img}
          />
        </a>
        <a
          href={"https://www.youtube.com/channel/UCZQUH15vDhcfI-U15LceN4w"}
          target="_blank"
          rel="noreferrer"
          className={s.social_link}
        >
          <img
            src={YouTube}
            width={35}
            height={35}
            alt="youtube"
            className={s.social_img}
          />
        </a>
        <a
          href={"https://www.instagram.com/dom_otpuska/"}
          target="_blank"
          rel="noreferrer"
          className={s.social_link}
        >
          <img
            src={Insta}
            width={35}
            height={35}
            alt="youtube"
            className={s.social_img}
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
