import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import s from "./Carousel.module.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { nightImages, dayImages } from "../../images/index.js";

const Carousel = () => {
  return (
    <section id="gallery" className={s.carousel}>
      <Tabs>
        <div className={s.tabs}>
          <TabList>
            <Tab>День</Tab>
            <Tab>Ночь</Tab>
          </TabList>
        </div>
        <TabPanel>
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true
            }}
            pagination={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className={s.mySwiper}
          >
            {dayImages.map((image, i) => (
              <SwiperSlide key={i} className={s.img}>
                <img alt={`Картинка ${i}`} src={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </TabPanel>
        <TabPanel>
          <Swiper
            grabCursor={true}
            centeredSlides={true}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true
            }}
            pagination={true}
            navigation={true}
            modules={[Pagination, Navigation]}
            className={s.mySwiper}
          >
            {nightImages.map((image, i) => (
              <SwiperSlide key={i}>
                <img alt={`Картинка ${i}`} src={image} />
              </SwiperSlide>
            ))}
          </Swiper>
        </TabPanel>
      </Tabs>
    </section>
  );
};

export default Carousel;
