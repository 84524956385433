// import { useState, useEffect } from "react";

import Layout from "../components/UI/Layout";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Carousel from "../components/Carousel";
import ScrollGallery from "../components/ScrollingGallery";
import Maps from "../components/Maps";
import Info from "../components/Info";
// import Loader from "../components/UI/Loader";
import Card from "../components/Card";
import ScrollToTop from "../components/UI/ScrollToTop";

const AppPage = () => {
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const timerID = setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  //
  //   return () => {
  //     if (timerID) {
  //       clearTimeout(timerID);
  //     }
  //   };
  // }, []);

  return (
    <>
      {/*{loading ? (*/}
      {/*  <Loader />*/}
      {/*) : (*/}
        <>
          <ScrollToTop />
          <Hero />
          <Layout>
            <ScrollGallery />
            <Info />
            <Carousel />
            <Card />
          </Layout>
          <Maps />
          <Footer />
        </>
      {/*)}*/}
    </>
  );
}

export default AppPage;
