import Button from "../UI/Button";

import logo from "../Footer/img/logo.png";
import Insta from "../Header/img/instagram.svg";

import s from "./HeaderDocs.module.css";

const HeaderDocs = () => {
  return (
    <header className={s.header}>
      <nav className={s.navigation}>
        <div className={s.social}>
          <a
            href={"https://mrr-72.ru/"}
            target="_blank"
            rel="noreferrer"
            className={s.logo}
          >
            <img src={logo} alt="logo" />
          </a>
          <a
            href={"https://www.instagram.com/dom_otpuska/"}
            target="_blank"
            rel="noreferrer"
            className={s.insta}
          >
            <img src={Insta} alt={"instagram"} />
          </a>
        </div>
        <div>
          <Button text="На главную" to="/" />
        </div>
      </nav>
    </header>
  );
};

export default HeaderDocs;
