import React from "react";

import s from "./Rules.module.css";

const RulesOfStay = () => {
  const privacyText = [
    "Лица, посещающие \"ТВОРЧЕСКУЮ ЛОКАЦИЮ 'ДОМ ОТПУСКА'\" (далее – Локация), обязаны соблюдать настоящие правила поведения на территории Локации.",
    "Договор посуточного найма \"ТВОРЧЕСКОЙ ЛОКАЦИИ 'ДОМ ОТПУСКА'\" заключается только при наличии у НАНИМАТЕЛЯ паспорта либо другого документа, удостоверяющего личность.",
    "Посещение Локации детьми до 16 лет допускается только в сопровождении взрослых.",
    "Расчетное время заезда – одни сутки: с 12.00 час. одного дня, до 11.00 часов следующего дня, если иное не предусмотрено Договором найма.",
    "Общий стиль поведения во время посещения – максимальная корректность и вежливость в отношениях с персоналом и между НАНИМАТЕЛЕМ и его гостями.",
    "НАНИМАТЕЛЬ несёт ответственность, в т.ч. материальную за нарушение: общественного порядка, санитарной и пожарной безопасности, причинение вреда окружающей среде, умышленное нанесение вреда здоровью и имуществу своих гостей, порчу или утерю имущества Локации. В случае уничтожения имущества или невозможности его дальнейшего использования стоимость имущества оценивается по его рыночной стоимости на момент обнаружения утраты (поломки).",
    "НАЙМОДАТЕЛЬ вправе отказать виновным в дальнейшем пребывании на территории без возмещения стоимости оплаченных услуг."
  ];

  const listItems = [
    "разводить костры на территории Локации запрещено, только в Мангальной зоне на специально отведенных для этого пикниковых зонах с использованием угля;",
    "выносить мебель из Дома (столы, стулья и др.) на улицу;",
    "оставлять детей без присмотра, особенно вблизи открытого водоема, в сауне, в банном чане. За детей, оставленных без присмотра, никто, помимо самих родителей ответственности не несет!",
    "купаться в Пруду, находящемся на территории Локации;",
    "заходить в Пруд во время работы фонтана – это опасно для жизни, так вода находится под электрическим напряжением!",
    "шуметь и слушать громко музыку на территории Локации в периоды с 22.00 до 08.00 часов в будние дни, с 22.00 до 09.00 часов в выходные и нерабочие праздничные дни, а также с 13 до 15 часов ежедневно. Уважайте право соседей на отдых и сон;",
    "проносить на территорию Локации любые виды оружия (в т.ч. травматическое), колющие и режущие предметы, наркотические и токсические вещества;",
    "проносить и хранить в Доме легковоспламеняющиеся, взрывоопасные и отравляющие предметы и вещества, в том числе газовые горелки и электронагревательные приборы;",
    "совершать любые действия, угрожающие сохранности имущества и окружающей среды Локации (в том числе пользование открытым огнем, разведение костров, курение в не отведенных для этих целей местах);",
    "разбрасывать мусор и окурки по территории Локации, в том числе и в Доме;",
    "появляться в служебных помещениях без разрешения НАЙМОДАТЕЛЯ;",
    "пользоваться фейерверками, хлопушками, петардами и прочими пиротехническими изделиями без согласования с НАЙМОДАЕЛЕМ;",
    "самопроизвольно организовывать выступления 'файершоу' и других огнеопасных программ."
  ];

  return (
    <div className={s.privacy}>
      <h1 className={s.title}>
        Правила пребывания на территории <br /> Творческой Локации 'Дом Отпуска'
      </h1>
      <div className={s.wrapper}>
        {privacyText.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <p className={s.prohibited}>ЗАПРЕЩАЕТСЯ:</p>
      <ul className={s.wrapper}>
        {listItems.map((item, i) => (
          <li key={i} className={s.paragraph}>
            {`- ${item}`}
          </li>
        ))}
      </ul>

      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          При возникновении чрезвычайных ситуаций на территории Локации
          незамедлительно звонить в службы экстренного реагирования по единому
          номеру телефона: "112" и поставить в известность НАЙМОДАТЕЛЯ.
        </p>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          За сохранность личного имущества НАНИМАТЕЛЯ и его гостей оставленным
          на территории Локации НАЙМОДАТЕЛЬ ответственности не несёт.
        </p>
      </div>
    </div>
  );
};

export default RulesOfStay;
