import { useEffect, useRef, useState } from "react";
import LightGallery from "lightgallery/react";
import Lenis from "@studio-freight/lenis";
import { useTransform, useScroll, motion } from "framer-motion";
import useResize from "../../helpers/useResize";
import { scrollImages } from "../../images";

import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import s from "./ScrollingGallery.module.css";

import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const Column = ({ images, y }) => {
  return (
    <motion.div className={s.column} style={{ y }}>
      {images.map((image, i) => {
        return (
          <LightGallery key={i} speed={500} plugins={[lgThumbnail, lgZoom]}>
            <a key={i} href={image} className={s.image_container}>
              <img src={image} alt={`Пример Дома Отпуска`} data-src={image} />
            </a>
          </LightGallery>
        );
      })}
      ;
    </motion.div>
  );
};

const ScrollGallery = () => {
  const gallery = useRef(null);
  const [dimension, setDimension] = useState({ width: 0, height: 0 });
  const { isScreenLg } = useResize();

  const { scrollYProgress } = useScroll({
    target: gallery,
    offset: ["start end", "end start"]
  });

  const { height } = dimension;
  const y = useTransform(scrollYProgress, [0, 1], [0, height * 2]);
  const y2 = useTransform(scrollYProgress, [0, 1], [0, height * 3.3]);
  const y3 = useTransform(scrollYProgress, [0, 1], [0, height * 1.25]);
  const y4 = useTransform(scrollYProgress, [0, 1], [0, height * 3]);

  useEffect(() => {
    const lenis = new Lenis();

    const raf = time => {
      lenis.raf(time);
      requestAnimationFrame(raf);
    };

    const resize = () => {
      setDimension({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", resize);
    requestAnimationFrame(raf);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <main>
      {isScreenLg ? (
        <>
          <div className={s.spacer}></div>
          <div ref={gallery} className={s.gallery}>
            <Column
              images={[
                scrollImages[0],
                scrollImages[1],
                scrollImages[2],
                scrollImages[4]
              ]}
              y={y}
            />
            <Column
              images={[
                scrollImages[3],
                scrollImages[5],
                scrollImages[6],
                scrollImages[7]
              ]}
              y={y2}
            />
            <Column
              images={[
                scrollImages[8],
                scrollImages[9],
                scrollImages[10],
                scrollImages[11]
              ]}
              y={y3}
            />
            <Column
              images={[
                scrollImages[12],
                scrollImages[13],
                scrollImages[14],
                scrollImages[15]
              ]}
              y={y4}
            />
          </div>
        </>
      ) : null}

      <div className={s.spacer}></div>
    </main>
  );
};

export default ScrollGallery;
