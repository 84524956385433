import Layout from "../components/UI/Layout";
import Footer from "../components/Footer";
import Button from "../components/UI/Button";
import TermsOfUse from "../components/Terms";
import ScrollToTop from "../components/UI/ScrollToTop";
import HeaderDocs from "../components/HeaderDocs";

const TermsPage = () => {
  return (
    <section>
      <ScrollToTop />
      <HeaderDocs />
      <Layout>
        <TermsOfUse />
        <Button text="На главную" to="/" />
      </Layout>
      <Footer />
    </section>
  );
};

export default TermsPage;
