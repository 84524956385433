import s from "../Rules/Rules.module.css";

const TermsOfUse = () => {
  const commonRules = [
    "При посещении сауны, банного чана и бассейна НАНИМАТЕЛЬ и его гости обязаны соблюдать настоящие правила.",
    "НАНИМАТЕЛЬ допускается до посещения сауны, банного чана и бассейна только при согласии с настоящими правилами.",
    "Посещение сауны, банного чана и бассейна является для посетителей делом сугубо добровольным, поэтому все риски, связанные с пользованием услугой, в случае несоблюдения им правил, посетитель берет на себя.",
    "Каждый посетитель несет личную ответственность за состояние своего здоровья и за здоровье находящихся с ним детей во время посещения сауны, банного чана и пользования бассейном. За травмы и несчастные случаи, связанные с нарушением правил пользования сауной и бассейном, НАЙМОДАТЕЛЬ ответственности не несет.",
    "НАНИМАТЕЛЬ несет ответственность за сохранность и порчу имущества/оборудования сауны. В случае порчи НАНИМАТЕЛЬ выплачивает полную стоимость испорченного имущества согласно Приложения № 1 Договора посуточного найма «Творческой локации «Дом отпуска»».",
    "НАЙМОДАТЕЛЬ вправе вызвать полицию в случае, если посетители не соблюдают настоящие правила и общественный порядок.",
    "НАЙМОДАТЕЛЬ не несет ответственности за личные вещи (драгоценности, часы, деньги и пр.), оставленные НАНИМАТЕЛЕМ во время посещения сауны, банного чана и бассейна.",
    "НАЙМОДАТЕЛЬ вправе отказать в посещении сауны, банного чана и бассейна лицам, находящимся в состоянии сильного алкогольного и/или наркотического опьянения, проявляющим неадекватное поведение (агрессию), а также любому клиенту без объяснения причины.",
    "НАЙМОДАТЕЛЬ вправе прервать сеанс посещения сауны/бассейна, если клиенты нарушают данные правила."
  ];

  const saunaRules = [
    "Запрещается самостоятельно растапливать дровяную каменку в сауне.",
    "Время использования сауны – 3 (три) часа. О начале времени посещения сауны, НАНИМАТЕЛЬ обязан предупредить НАЙМОДАТЕЛЯ не позднее чем за 1 (один) час до предполагаемого времени посещения или согласовать время пользования сауной заранее в момент заезда в Локацию.",
    "Запрещается посещать сауну при наличии инфекционных, кожных, грибковых и других заболеваний, плохом самочувствии, температуре, иных признаков нездорового состояния, представляющих опасность для личного здоровья и безопасности, а также для здоровья и безопасности других посетителей.",
    "В сауне запрещается использовать крема, скрабы и масла для тела.",
    "Запрещается поливать камни смесями с ароматическими добавками.",
    "Запрещено прикасаться к поверхности дровяной каменки, во избежание получения ожогов.",
    "Каждый посетитель несет личную ответственность за состояние своего здоровья во время посещения сауны.",
    "При возникновении пожара необходимо без паники максимально быстро (если позволяет ситуация) покинуть сауну и Дом, используя основные и запасные (пожарные) выходы или лестницы. Если горение только началось, вы его можете затушить водой, или при помощи огнетушителя. Ни в коем случае не тушить водой горящие электропроводку и электроприборы, находящиеся под напряжением — это опасно для жизни! Вызовите пожарную охрану по телефонам: '101', '01' или по телефону единой дежурной диспетчерской службы - '112' сообщив свой точный адрес местонахождения, объект пожара и встретить пожарную охрану. При необходимости окажите первую помощь пострадавшим.",
    "В случае возникновения чрезвычайных ситуаций, посетители обязаны покинуть помещение, сохраняя спокойствие и не создавая паники.",
    "Сообщать НАЙМОДАТЕЛЮ о любом происшествии, случившемся на территории Локации."
  ];

  const chanRules1 = [
    "Запрещается самостоятельно растапливать и подтапливать Чан.",
    "Время использования Чана – 3 (три) часа. О начале времени посещения Чана, НАНИМАТЕЛЬ обязан предупредить НАЙМОДАТЕЛЯ не позднее чем за 3 (три) часа до предполагаемого времени посещения или согласовать время пользования Чаном заранее в момент заезда в Локацию.",
    "Запрещается посещать Чан при наличии инфекционных, кожных, грибковых и других заболеваний, плохом самочувствии, температуре, иных признаков нездорового состояния, представляющих опасность для личного здоровья и безопасности, а также для здоровья и безопасности других посетителей.",
    "НАЙМОДАТЕЛЬ вправе ограничивать круг лиц, допущенных в Чан в целях обеспечения безопасности, контролировать количество купающихся лиц учитывая технические возможности Чана.",
    "Посещение Чана возможно только в купальных костюмах: плавки, плавки-шорты, купальники (для женщин), купальная одежда для детей любого возраста. Дети до 1,5 лет могут посещать Чан исключительно в специальных плавательных подгузниках. В Чан допускается подниматься босиком. Перед посещением Чана посетители обязаны принять душ с применением моющих средств.",
    "Рекомендуется находиться в банном чане более 15-20 минут во избежание перегрева."
  ];

  const chanRules2 = [
    "Нырять и прыгать с бортиков стальной чаши Чана. Необходимо аккуратно погружаться в стальную чашу: спускаться, подниматься по лестнице;",
    "Находиться в емкости, не наполненной полностью водой;",
    "Оставлять без присмотра детей и пожилых людей;",
    "Входить в воду с жевательной резинкой, продуктами питания и любыми напитками;",
    "Посещать Чан, расположенный на открытом воздухе, при неблагоприятных условиях (ветер более 15 м/с., дождь, снег, град, гроза);",
    "Брать с собой в Чан: колюще-режущие предметы, предметы из стекла и иные хрупкие предметы, дающие осколки (кроме солнцезащитных очков); алкоголь, наркотические вещества, табачные изделия и приравнённые к ним изделия; лекарства и медикаменты.",
    "Во избежание травматизма и угрозы для жизни и здоровья людей, НАЙМОДАТЕЛЬ вправе попросить покинуть Чан лиц, не соблюдающих настоящие Правила."
  ];

  const chanRules3 = [
    "Беременным женщинам на поздних сроках беременности;",
    "Лицам с грудными детьми;",
    "Лицам с кардиостимуляторами и заболеваниями сердца;",
    "Лицам, имеющим противопоказания к посещению Чана по состоянию здоровья;",
    "С другими заболеваниями, которые могут обостриться во время пребывания на солнце и/или длительном пребывании в воде."
  ];

  const chanRules4 = [
    "Пользование Чаном несовершеннолетними лицами допускается только в сопровождении родителей, либо иных лиц, являющихся законными представителями несовершеннолетних (далее по тексту – «Родители»), которые обязаны беспрерывно следить за поведением несовершеннолетних лиц вблизи Чана и за соблюдением ими настоящих Правил пользования Чаном.",
    "Не подпускать маленьких детей близко к внешней поверхности стальной чаши, печи, ветрозащиты, дымоходу. Это может привести к серьезным ожогам.",
    "Ответственность за безопасность и сохранность здоровья и жизни несовершеннолетних лиц в Чане и всей территории, прилегающей к нему, несут исключительно Родители."
  ];

  const poolRules1 = [
    "Купание в бассейне возможно только в купальных костюмах: плавки, плавки-шорты, купальники (для женщин), купальная одежда для детей любого возраста. Дети до 1,5 лет могут посещать бассейн исключительно в специальных плавательных подгузниках.",
    "Перед пользованием бассейном, посетитель обязан вымыться с мылом под душем.",
    "Не допускается втирать в кожу различные кремы и мази перед пользованием бассейном.",
    "Дети до 14 лет, пользоваться бассейном, должны только в сопровождении взрослых.",
    "Перед спуском в бассейн необходимо снять обувь.",
    "Для спуска в чашу бассейна необходимо пользоваться лестницей. Опускаться нужно, не спеша, держась за перила.",
    "Не допускается попадание в чашу посторонних предметов – это может вывести из строя оборудование бассейна."
  ];

  const poolRules2 = [
    "вносить в бассейн или ставить на бортик стеклянную посуду и стеклянные предметы;",
    "прыгать в бассейн;",
    "бегать, толкаться и играть вблизи бассейна во избежание падения и травматизма."
  ];

  const poolRules3 = [
    "Посетители с инфекционными и кожными заболеваниями не могут использовать бассейн.",
    "Бережно относиться к оборудованию (за порчу оборудования взимается плата в размере его стоимости, расходов по закупке и установке).",
    "Каждый клиент несет личную ответственность за состояние своего здоровья во время посещения бассейна."
  ];

  return (
    <div className={s.privacy}>
      <h1 className={s.title}>
        Порядок и Правила пользования сауной и бассейном и банным чаном
        НАНИМАТЕЛЯМИ и иными посетителями Творческой Локации 'Дом Отпуска'
      </h1>
      <h2 className={s.subtitle}>
        Настоящие правила устанавливают порядок и рекомендации по пользованию
        сауной, банным чаном и бассейном на территории "ТВОРЧЕСКУЮ ЛОКАЦИЮ 'ДОМ
        ОТПУСКА'" и направлены на обеспечение безопасности клиентов и их
        здоровья и являются обязательными для соблюдения всеми посетителями.
      </h2>
      <h3 className={s.subtitle}>1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <div className={s.wrapper}>
        {commonRules.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`1.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <h3 className={s.subtitle}>
        2. ПРАВИЛА И РЕКОМЕНДАЦИИ ПО ИСПОЛЬЗОВАНИЮ САЙНОЙ
      </h3>
      <div className={s.wrapper}>
        {saunaRules.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`2.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <h3 className={s.subtitle}>3. ПРАВИЛА И ПОЛЬЗОВАНИЯ БАННЫМ ЧАНОМ</h3>
      <div className={s.wrapper}>
        {chanRules1.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`3.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <p className={s.prohibited}>
        3.7 В целях обеспечения безопасности при посещении Чана запрещается:
      </p>
      <ul className={s.wrapper}>
        {chanRules2.map((item, i) => (
          <li key={i} className={s.paragraph}>
            {`- ${item}`}
          </li>
        ))}
      </ul>

      <p className={s.prohibited}>
        3.8 НАЙМОДАТЕЛЬ не рекомендует посещение Чана следующим лицам:
      </p>
      <ul className={s.wrapper}>
        {chanRules3.map((item, i) => (
          <li key={i} className={s.paragraph}>
            {`- ${item}`}
          </li>
        ))}
      </ul>

      <p className={s.prohibited}>
        3.9. Особенности пользования Чаном несовершеннолетними лицами (детьми):
      </p>
      <ul className={s.wrapper}>
        {chanRules4.map((item, i) => (
          <li key={i} className={s.paragraph}>
            {`- ${item}`}
          </li>
        ))}
      </ul>

      <p className={s.prohibited}>
        3.10. В соответствии законодательством РФ на посетителе лежит
        ответственность по возмещении причиненного вреда, за порчу имущества
        и/или загрязнение воды в Чане.
      </p>
      <p className={s.prohibited}>
        3.11. Посетители должны строго соблюдать и выполнять настоящие правила и
        все распоряжения НАЙМОДАТЕЛЯ. Несоблюдение настоящих Правил является
        основанием для запрета на пользование Чаном.
      </p>

      <h3 className={s.subtitle}>4. ПРАВИЛА ПОЛЬЗОВАНИЯ БАССЕЙНОМ</h3>
      <div className={s.wrapper}>
        {poolRules1.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`4.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <p className={s.prohibited}>4.8 ЗАПРЕЩАЕТСЯ:</p>
      <ul className={s.wrapper}>
        {poolRules2.map((item, i) => (
          <li key={i} className={s.paragraph}>
            {`- ${item}`}
          </li>
        ))}
      </ul>

      <div className={s.wrapper}>
        {poolRules3.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`4.${i + 9}. ${paragraph}`}
          </p>
        ))}
      </div>

      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          За несоблюдение настоящего Порядка и Правил установлен штраф, в
          размере 5000(пять тысяч) рублей 00 копеек!
        </p>
      </div>
    </div>
  );
};

export default TermsOfUse;
