import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn, faToggleOff } from "@fortawesome/free-solid-svg-icons";
import { motion, useAnimation } from "framer-motion";

import logo from "../Footer/img/logo.png";
import Insta from "./img/instagram.svg";
import s from "./Header.module.css";

const Header = () => {
  const [isScroll, setIsScroll] = useState(true);
  const [toggle, setToggle] = useState(true);
  const controls = useAnimation();

  function toggleHandler() {
    setToggle(prev => !prev);
    setIsScroll(prev => !prev);
  }

  useEffect(() => {
    const scrollY = 100;
    const handleScroll = () => {
      if (window.scrollY > scrollY) {
        setIsScroll(false);
        setToggle(false);
      } else {
        setIsScroll(true);
        setToggle(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const headerVariants = {
    scrolled: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      padding: "16px 12px", // Первая анимация - изменение padding
      transition: {
        duration: 1,
        delay: 0.2, // Задержка анимации
        ease: [0.4, 0, 0.2, 1]
      }
    },
    notScrolled: {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      padding: "8px 12px",
      transition: {
        duration: 1,
        delay: 0.2, // Задержка анимации
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  const textAnimation = {
    hidden: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 1,
        delay: 0.1, // Задержка анимации
        ease: [0.4, 0, 0.2, 1]
      }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.5, // Задержка анимации
        ease: [0.4, 0, 0.2, 1]
      }
    }
  };

  useEffect(() => {
    async function animateHeader() {
      await controls.start(isScroll ? "scrolled" : "notScrolled");
    }

    animateHeader();
  }, [controls, isScroll]);

  return (
    <motion.header
      className={s.header}
      variants={headerVariants}
      initial="scrolled"
      animate={controls}
    >
      <nav className={s.navigation}>
        <div className={s.social}>
          <a
            href={"https://mrr-72.ru/"}
            target="_blank"
            rel="noreferrer"
            className={s.logo}
          >
            <img src={logo} alt="logo" />
          </a>
          <a
            href={"https://www.instagram.com/dom_otpuska/"}
            target="_blank"
            rel="noreferrer"
            className={s.insta}
          >
            <img src={Insta} alt={"instagram"} />
          </a>
        </div>
        {isScroll ? (
          <motion.ul
            className={s.list}
            initial="hidden" // Начальное состояние анимации текста
            animate="visible" // Анимация текста
            variants={textAnimation}
          >
            <li>
              <a href="/#info">
                <span>Информация</span>
              </a>
            </li>
            <li>
              <a href="/#gallery">
                <span>Галерея</span>
              </a>
            </li>
            <li>
              <a href="/#contacts">
                <span>Контакты</span>
              </a>
            </li>
          </motion.ul>
        ) : null}
        <div className={s.toggle} onClick={toggleHandler}>
          {toggle ? (
            <FontAwesomeIcon
              style={{ width: "40px", height: "40px" }}
              icon={faToggleOn}
              size={"lg"}
            />
          ) : (
            <FontAwesomeIcon
              style={{ width: "40px", height: "40px" }}
              icon={faToggleOff}
              size={"lg"}
            />
          )}
        </div>
      </nav>
    </motion.header>
  );
};

export default Header;
