import s from "./Table.module.css";

const Table = () => {
  return (
    <table>
      <thead>
        <tr className={s.title}>
          <th>Наименование имущества</th>
          <th>Количество, шт.</th>
          <th>Стоимость за шт. руб.</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>Утеря ключа</td>
          <td>1</td>
          <td>500,00</td>
        </tr>
        <tr>
          <td>Утеря электронного ключа от ворот</td>
          <td>1</td>
          <td>5000,00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Кухня-гостиная</td>
        </tr>
        <tr>
          <td>Кухонный гарнитур</td>
          <td>1</td>
          <td>250 000,00</td>
        </tr>
        <tr>
          <td>Кухонный гарнитур</td>
          <td>1</td>
          <td>250 000,00</td>
        </tr>
        <tr>
          <td>Тарелка</td>
          <td>1</td>
          <td>300,00</td>
        </tr>
        <tr>
          <td>Миска</td>
          <td>1</td>
          <td>300,00</td>
        </tr>
        <tr>
          <td>Чашка</td>
          <td>1</td>
          <td>200,00</td>
        </tr>
        <tr>
          <td>Бокал для вина</td>
          <td>1</td>
          <td>500,00</td>
        </tr>
        <tr>
          <td>Бокал для шампанского</td>
          <td>1</td>
          <td>500,00</td>
        </tr>
        <tr>
          <td>Блюдо</td>
          <td>1</td>
          <td>700,00</td>
        </tr>
        <tr>
          <td>Камин</td>
          <td>1</td>
          <td>200,000.00</td>
        </tr>
        <tr>
          <td>Музыкальная акустическая колонка</td>
          <td>1</td>
          <td>40,000.00</td>
        </tr>
        <tr>
          <td>Барабанная установка</td>
          <td>1</td>
          <td>250,000.00</td>
        </tr>
        <tr>
          <td>Акустическая гитара</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Телевизор + пульт</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Игровая консоль PlayStation 5</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Стол обеденный</td>
          <td>1</td>
          <td>10,000.00</td>
        </tr>
        <tr>
          <td>Диван кожаный</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr>
          <td>Кресло кожаное (бордо)</td>
          <td>1</td>
          <td>9,000.00</td>
        </tr>
        <tr>
          <td>Бар-холодильник</td>
          <td>1</td>
          <td>20,000.00</td>
        </tr>
        <tr>
          <td>Люстра большая</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Люстра маленькая</td>
          <td>1</td>
          <td>3,500.00</td>
        </tr>
        <tr>
          <td>Прожектор</td>
          <td>1</td>
          <td>1,000.00</td>
        </tr>
        <tr>
          <td>Входная дверь</td>
          <td>1</td>
          <td>60,000.00</td>
        </tr>
        <tr>
          <td>Дверная ручка (замок)</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Окно</td>
          <td>1</td>
          <td>36,000.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Сауна</td>
        </tr>
        <tr>
          <td>Стеклянная дверь</td>
          <td>1</td>
          <td>120,000.00</td>
        </tr>
        <tr>
          <td>Дверная ручка (замок)</td>
          <td>1</td>
          <td>10,000.00</td>
        </tr>
        <tr>
          <td>Дровяная каменка</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Палок-скамья</td>
          <td>1</td>
          <td>10,000.00</td>
        </tr>
        <tr>
          <td>Ковш для воды</td>
          <td>1</td>
          <td>3,000.00</td>
        </tr>
        <tr>
          <td>Емкость для воды</td>
          <td>1</td>
          <td>3,000.00</td>
        </tr>
        <tr>
          <td>Светильник</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Душевая стойка со смесителем</td>
          <td>1</td>
          <td>20,000.00</td>
        </tr>
        <tr>
          <td>Светильник "Бульдог"</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Огнетушитель</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Сан. узел</td>
        </tr>
        <tr>
          <td>Светильник "Бульдог"</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Большое зеркало с подсветкой</td>
          <td>1</td>
          <td>20,000.00</td>
        </tr>
        <tr>
          <td>Унитаз</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr>
          <td>Тумба с двумя раковинами</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Смеситель</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>
            Туалетные аксессуары, в том числе: ёршик, мусорное ведро, ёмкость
            для мыла
          </td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Металлическая полка</td>
          <td>1</td>
          <td>700.00</td>
        </tr>
        <tr>
          <td>Дверь</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Дверная ручка (замок)</td>
          <td>1</td>
          <td>10,000.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Коридор</td>
        </tr>
        <tr>
          <td>Люстра большая</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Комод (винтаж)</td>
          <td>1</td>
          <td>15,000.00</td>
        </tr>
        <tr>
          <td>Картина</td>
          <td>1</td>
          <td>12,500.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Спальня</td>
        </tr>
        <tr>
          <td>Постельное белье</td>
          <td>1</td>
          <td>2,000.00</td>
        </tr>
        <tr>
          <td>Плед</td>
          <td>1</td>
          <td>2,000.00</td>
        </tr>
        <tr>
          <td>Подушки</td>
          <td>1</td>
          <td>300.00</td>
        </tr>
        <tr>
          <td>Одеяло</td>
          <td>1</td>
          <td>750.00</td>
        </tr>
        <tr>
          <td>Кровать 2-х спальная</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Прикроватная тумба</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Телевизор + пульт</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Дверь</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Ручка (замок) меж.комн. дверь</td>
          <td>1</td>
          <td>10,000.00</td>
        </tr>
        <tr>
          <td>Окно</td>
          <td>1</td>
          <td>90,000.00</td>
        </tr>
        <tr>
          <td>Люстра</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Светильник потолочный прикроватный</td>
          <td>1</td>
          <td>1,500.00</td>
        </tr>
        <tr>
          <td>Шкаф для книг</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Столик</td>
          <td>1</td>
          <td>8,000.00</td>
        </tr>
        <tr>
          <td>Банкетка</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr>
          <td>Настольный светильник</td>
          <td>1</td>
          <td>800.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Детская комната</td>
        </tr>
        <tr>
          <td>Межкомнатная дверь</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Ручка (замок) меж.комн. дверь</td>
          <td>1</td>
          <td>10,000.00</td>
        </tr>
        <tr>
          <td>Ковролин</td>
          <td>1</td>
          <td>80,000.00</td>
        </tr>
        <tr>
          <td>Теннисный стол</td>
          <td>1</td>
          <td>15,000.00</td>
        </tr>
        <tr>
          <td>Ракетка для тенниса</td>
          <td>1</td>
          <td>150.00</td>
        </tr>
        <tr>
          <td>Люстра</td>
          <td>1</td>
          <td>70,000.00</td>
        </tr>
        <tr>
          <td>Светодиодная люстра</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Придомовая территория</td>
        </tr>
        <tr>
          <td>Банный чан</td>
          <td>1</td>
          <td>200,000.00</td>
        </tr>
        <tr>
          <td>Бассейн</td>
          <td>1</td>
          <td>180,000.00</td>
        </tr>
        <tr>
          <td>Фонтан в пруду</td>
          <td>1</td>
          <td>215,000.00</td>
        </tr>
        <tr>
          <td>Подвесное кресло-кокон</td>
          <td>1</td>
          <td>40,000.00</td>
        </tr>
        <tr>
          <td>Пуфы-лежанки</td>
          <td>1</td>
          <td>7,000.00</td>
        </tr>
        <tr>
          <td>Качель-кольцо с подсветкой</td>
          <td>1</td>
          <td>90,000.00</td>
        </tr>
        <tr>
          <td>Садовая качель-кровать с 4-мя подушками</td>
          <td>1</td>
          <td>80,000.00</td>
        </tr>
        <tr>
          <td>Пластиковые шезлонги с подушками</td>
          <td>1</td>
          <td>7,000.00</td>
        </tr>
        <tr>
          <td>Железные шезлонги с деревянной обрешёткой</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Скамья деревнейшинная «Бесконечность»</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Скамья деревнейшинная «Сердце»</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Шары-светильники (бол.)</td>
          <td>1</td>
          <td>6,000.00</td>
        </tr>
        <tr>
          <td>Шары-светильники (мал.)</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Арт объект «Стена-водопад»</td>
          <td>1</td>
          <td>300,000.00</td>
        </tr>
        <tr>
          <td>Стул садовый раскладной (зеленый)</td>
          <td>1</td>
          <td>500.00</td>
        </tr>
        <tr>
          <td>Стул садовый (серый)</td>
          <td>1</td>
          <td>700.00</td>
        </tr>
        <tr>
          <td>Угольный гриль-барбекю</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Очаг металлический</td>
          <td>1</td>
          <td>40,000.00</td>
        </tr>
        <tr>
          <td>Арт объект «МRR 72»</td>
          <td>1</td>
          <td>300,000.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Мангальная зона</td>
        </tr>
        <tr>
          <td>Остров для приготовления блюд</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr>
          <td>Самовар</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr>
          <td>Мойка</td>
          <td>1</td>
          <td>20,000.00</td>
        </tr>
        <tr>
          <td>Тандыр</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
        <tr>
          <td>Мангал</td>
          <td>1</td>
          <td>100,000.00</td>
        </tr>
        <tr>
          <td>Казан</td>
          <td>1</td>
          <td>30,000.00</td>
        </tr>
        <tr>
          <td>Решетка-гриль</td>
          <td>1</td>
          <td>5,000.00</td>
        </tr>
        <tr>
          <td>Шампур</td>
          <td>1</td>
          <td>300.00</td>
        </tr>
        <tr>
          <td>Шумовка</td>
          <td>1</td>
          <td>1,000.00</td>
        </tr>
        <tr className={s.title}>
          <td colSpan="3">Парковка</td>
        </tr>
        <tr>
          <td>Баскетбольное кольцо с приставной лестницей</td>
          <td>1</td>
          <td>50,000.00</td>
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
