import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import AppPage from "./pages/AppPage";
import RulesPage from "./pages/RulesPage";
import TermsPage from "./pages/TermsPage";
import RentalPage from "./pages/RentalPage";
import Loader from './components/UI/Loader';

import "./styles/index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppPage />
  },
  {
    path: "rules",
    element: <RulesPage />
  },
  {
    path: "terms",
    element: <TermsPage />
  },
  {
    path: "rental",
    element: <RentalPage />
  }
], {basename: '/'});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} fallbackElement={<Loader />}/>
  </React.StrictMode>
);
