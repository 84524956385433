import { motion } from "framer-motion";
import s from "./Info.module.css";
import { infoImages } from "../../images";

const textAnimation = {
  hidden: {
    x: -100,
    opacity: 0
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  })
};

const Info = () => {
  return (
    <div id="info" className={s.container}>
      {infoImages.map((item, i) => (
        <motion.article
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 1, once: true }}
          key={i}
          className={s.info}
        >
          <div className={s.wrapper_img}>
            <img
              src={item.imgSrc}
              alt={`Преимущество ${i}`}
              className={s.img}
            />
          </div>
          <div className={s.wrapper_text}>
            <motion.h3 custom={1} variants={textAnimation} className={s.title}>
              {item.text}
            </motion.h3>
            <div className={s.wrapper_desc}>
              {item.description.map((desc, i) => (
                <motion.p
                  custom={2 * (i + 1)}
                  variants={textAnimation}
                  key={i}
                  className={s.desc}
                >
                  {`- ${desc.subtext}`}
                </motion.p>
              ))}
            </div>
          </div>
        </motion.article>
      ))}
    </div>
  );
};

export default Info;
