import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGift,
  faBriefcase,
  faBirthdayCake,
  faHeart,
  faMusic,
  faCocktail,
  faChalkboardTeacher
} from "@fortawesome/free-solid-svg-icons";
import Button from "../UI/Button";

import s from "./Card.module.css";

const Card = () => {
  return (
    <article className={s.card}>
      <div className={s.img}></div>
      <div className={s.desc}>
        <h3 className={s.title}>Отличное место для:</h3>
        <div className={s.subtitle}>
          <FontAwesomeIcon icon={faBirthdayCake} style={{ color: "#D19C29" }} />
          <p>- свадеб</p>
        </div>
        <div className={s.subtitle}>
          <FontAwesomeIcon icon={faBriefcase} style={{ color: "#D19C29" }} />
          <p>- корпоративов</p>
        </div>
        <div className={s.subtitle}>
          <FontAwesomeIcon icon={faGift} style={{ color: "#D19C29" }} />
          <p>- дней рождения</p>
        </div>
        <div className={s.subtitle}>
          <FontAwesomeIcon icon={faHeart} style={{ color: "#D19C29" }} />
          <p>- романтических свиданий</p>
        </div>
        <div className={s.subtitle}>
          <FontAwesomeIcon icon={faCocktail} style={{ color: "#D19C29" }} />
          <p>- тематических вечеринок</p>
        </div>
        <div className={s.subtitle}>
          <FontAwesomeIcon
            icon={faChalkboardTeacher}
            style={{ color: "#D19C29" }}
          />
          <p>- мастер-классов</p>
        </div>
        <div className={s.subtitle}>
          <FontAwesomeIcon icon={faMusic} style={{ color: "#D19C29" }} />
          <p>- фуршетов, банкетов</p>
        </div>
        <div className={s.wrapperBtn}>
          <Button text="Забронировать" href="https://n956844.yclients.com/" />
        </div>
      </div>
    </article>
  );
};

export default Card;
