import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import Header from "../Header";
import { heroImages } from "../../images/index.js"

import "swiper/css";
import s from "./Hero.module.css";

const textAnimation = {
  hidden: {
    opacity: 0.2
  },
  visible: {
    opacity: 1
  }
};

const Hero = () => {
  return (
    <section className={s.hero}>
      <Header />
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        className={s.wrapper}
      >
        <motion.h1 variants={textAnimation} className={s.title}>
          33 удовольствия.
          <br />
          Дом Отпуска в Тюмени.
          <br />
          Природа. Творчество. Чилл.
        </motion.h1>
      </motion.div>
      <Swiper
        centeredSlides={true}
        slidesPerView={1}
        speed={800}
        loop={true}
        spaceBetween={16}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false
        }}
        modules={[Autoplay]}
        className={s.hero_swiper}
      >
        {heroImages.map((image, index) => (
          <SwiperSlide key={index}>
            <div className={s.slide}>
              <img
                src={image}
                alt={`Дом отдыха ${index + 1}`}
                className={s.slider_img}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;
