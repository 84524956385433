import s from './Loader.module.css';

const Loader = () => {
    return (
        <section className={s.wrapper}>
            <div className={s.loader}></div>
        </section>

    )
}

export default Loader;
