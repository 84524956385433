import s from "../../Rules/Rules.module.css";

const TableSign = () => {
  return (
    <div className={s.sign}>
      <div className={s.signWrapper}>
        <p>НАЙМОДАТЕЛЬ</p>
        <p> ___________________/______________/</p>
      </div>
      <div className={s.signWrapper}>
        <p>НАНИМАТЕЛЬ</p>
        <p> ___________________/______________/</p>
      </div>
    </div>
  );
};

export default TableSign;
