import Layout from "../components/UI/Layout";
import RulesOfStay from "../components/Rules";
import Button from "../components/UI/Button";
import ScrollToTop from "../components/UI/ScrollToTop";
import Footer from "../components/Footer";
import HeaderDocs from "../components/HeaderDocs";


const RulesPage = () => {
  return (
    <section>
      <ScrollToTop />
      <HeaderDocs />
      <Layout>
        <RulesOfStay />
        <Button text="На главную" to="/" />
      </Layout>
      <Footer />
    </section>
  );
};

export default RulesPage;
