import s from "../../Rules/Rules.module.css";

const RentalRules = () => {
  const contract1 = [
    " НАЙМОДАТЕЛЬ передаёт во временное пользование НАНИМАТЕЛЮ «Творческую локацию «Дом отпуска»», с находящимся в ней имуществом и прилегающим земельным участком, расположенную по адресу: Российская Федерация, Тюменская область, Тюменский муниципальный район, Переваловское с.п., с. Перевалово, ул. Пятницкая, д. 16 (далее – Локация)."
  ];

  const contract2 = [
    " Одноэтажный жилой дом, состоящий из: кухни-гостиной, 1 (одной) спальной комнаты с 2-спальной кроватью, 1 (одной) детской комнаты, сауны и сан. узла (далее – Дом).",
    " Придомовую зону отдыха, оборудованную: банным чаном, бассейном, мангальной зоной, шезлонгами, качелями, баскетбольным кольцом и т.д.",
    " Пруд, купание в котором запрещено. Заходить в Пруд во время работы фонтана опасно для жизни, так как вода находится под электрическим напряжением!",
    " Зону автомобильной парковки."
  ];

  const contract3 = [
    "По соглашению сторон договором устанавливается плата за наем Локации 50 000 (Пятьдесят тысяч) рублей 00 копеек, без НДС. Оплата в полном объеме производится НАНИМАТЕЛЕМ или его представителем до заселения в Дом.",
    "1. Дополнительные услуги: _______________ (сумма и описание, рублей 00 копеек, без НДС).",
    "1. Общая сумма: _______________ (сумма, рублей 00 копеек, без НДС). Оплата в полном объеме производится арендатором или его представителем до заселения в дом."
  ];

  const contract4 = [
    " Передать Дом, пригодный для использования, отвечающий необходимым санитарным и техническим требованиям, обычно предъявляемым к жилым помещениям, то есть - водоснабжением, теплоснабжением, электроснабжением - все системы находятся в рабочем состоянии.",
    " Осуществлять техобслуживание Локации, исправлять последствия повреждений и неисправностей, возникших не по вине НАНИМАТЕЛЯ;",
    " Подписание данного Договора одновременно является подтверждением того, что вышеуказанные бытовые приборы приняты в исправном состоянии."
  ];

  const contract5 = [
    " Производить осмотр сданной в наем Локации и находящегося на ее территории имущества на предмет сохранности и санитарного состояния.",
    " Требовать от НАНИМАТЕЛЯ соблюдения Правил пользования Локацией, в том числе: сауной, банным чаном, бассейном.",
    " Требовать от НАНИМАТЕЛЯ внесения платы за наём Локации отдыха за весь период проживания в день заезда и подписания настоящего договора.",
    " Требовать от НАНИМАТЕЛЯ освобождения Локации по истечении срока договора найма или досрочно в случае нарушений п.3.1.1.-3.1.8. настоящего договора при этом оплата не возвращается.",
    " Требовать возмещения ущерба, причиненного Локации и находящемуся на ней имуществу по вине НАНИМАТЕЛЯ или его гостей."
  ];

  const contract6 = [
    " Использовать локацию и находящееся на ней имущество для проживания в период срока найма, включая проживание других лиц.",
    " Потребовать от НАЙМОДАТЕЛЯ утранения неисправностей, препятствующих пользованию Локацией, возникших не по вине НАНИМАТЕЛЯ"
  ];

  const contract7 = [
    " При заезде, в полном объеме оплатить стоимость найма Локации, установленную п. 1.3.2. настоящего договора",
    " Использовать Локацию только для собственных нужд (без права передачи в субаренду)",
    " Бережно относиться к имуществу, находящемуся на территории Локации. При обнаружении признаков аварийного состояния сантехнического, электротехнического и прочего оборудования немедленно устранять последствия аварий и сообщить об этом НАЙМОДАТЕЛЮ (Администратору).",
    " Допускать в любое время при авариях или иных форс мажорных обстоятельствах, в Дом работников НАЙМОДАТЕЛЯ или самого НАЙМОДАТЕЛЯ.",
    " Возвратить НАЙМОДАТЕЛЮ Локацию в том же состоянии, в котором она была передана в наём.",
    " НАНИМАТЕЛЬ несет полную материальную ответственность за действия своих гостей, причинивших материальный ущерб НАЙМОДАТЕЛЮ за свой счет при нанесении ему ущерба действиями или непринятия необходимых и своевременных мер по предотвращению ущерба, имуществу НАЙМОДАТЕЛЯ (Приложение № 1).",
    " НАНИМАТЕЛЬ и его гости обязаны соблюдать правила общественного порядка, не превышать уровень допустимого шума (в периоды с 22.00 до 08.00 часов в будние дни, с 22.00 до 09.00 часов в выходные и нерабочие праздничные дни, а также с 13 до 15 часов ежедневно) в соответствии с Законом Тюменской области от 29.03.2022 N 3 'Об обеспечении тишины и покоя граждан в Тюменской области' (в случае нарушения оплачивает штраф самостоятельно), соблюдать техники безопасности, противопожарной безопасности на территории Локации. Не разбрасывать по Дому и по придомовой территории мусор, окурки, отходы, а паковать их в полиэтиленовые пакеты",
    " НАНИМАТЕЛЬ обязуется не проживать в Локации с животными без согласования с НАЙМОДАТЕЛЕМ, курить в отведенном для этого месте (мангальная площадка). Не создавать конфликтные ситуации между с собой и сотрудниками НАЙМОДАТЕЛЯ.",
    " В случае отказа оплачивать проживание, отказа оплачивать штраф, в случае создания конфликтной ситуации на территории НАЙМОДАТЕЛЬ вправе выселить НАНИМАТЕЛЯ раньше срока без возврата платы за наем Локации, либо ограничить НАНИМАТЕЛЯ в коммуникациях (отключить свет и воду)"
  ];

  const contract8 = [
    " Оплата посуточного найма Локации производитя после подписания договора, перед заселением, при отказе произвести оплату в вышеуказанный срок настоящий договор найма утрачивает свою силу, заселение не осуществляется.",
    " Оплата производится посредством внесения наличных денежных средств или перечислением на счет НАЙМОДАТЕЛЯ.",
    " В случае расторжения настоящего Договора по инициативе НАНИМАТЕЛЯ по любым причинам досрочно, оплаченная сумма за наем Локации возвращается НАНИМАТЕЛЮ в течение 10 (десяти) календарных дней."
  ];

  const contract9 = [
    " Настоящий договор составлен в 2-х экземплярах, имеющих равную юридическую силу, один из которых находится у НАЙМОДАТЕЛЯ, другой - у НАНИМАТЕЛЯ",
    " Договор вступает в силу с момента полной оплаты НАНИМАТЕЛЕМ всей суммы стоимости найма Локации.",
    " Поздний заезд НАНИМАТЕЛЯ в Локацию не влечет изменения срока выезда, определенного договором",
    " Условия настоящего договора по посуточному или почасовому найму Локации сохраняют свою силу на весь срок действия договора",
    " Настоящий договор может быть продлен по согласованию сторон при условии внесения дополнительной платы и отсутствия оплаченной брони другими НАНИМАТЕЛЯМИ на дальнейший период.",
    " Подписанием настоящего Договора, НАНИМАТЕЛЬ подтверждает, что ознакомлен с Правилами пользования Локацией, в том числе: сауной, банным чаном, бассейном и согласен их соблюдать."
  ];

  return (
    <>
      <h3 className={s.subtitle}>1. ПРЕДМЕТ ДОГОВОРА.</h3>
      <div className={s.wrapper}>
        {contract1.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`1.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <ul className={s.wrapper}>
        <p className={s.paragraph}>
          «Творческая локация «Дом отпуска»» включает в себя:
        </p>
        {contract2.map((item, i) => (
          <li key={i} className={s.paragraph}>
            {`- ${item}`}
          </li>
        ))}
      </ul>

      <div className={s.wrapper}>
        <p className={s.paragraph}>1.2 Сроки найма:</p>
        <p className={s.paragraph}>
          {" "}
          - Дата заезда ______________, время заезда - 12.00 часов дня.
        </p>
        <p className={s.paragraph}>
          {" "}
          - Дата отъезда _____________, время выезда - 11.00 часов дня.
        </p>
        {contract3.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`1.${i + 3}. ${paragraph}`}
          </p>
        ))}
      </div>
      <h3 className={s.subtitle}>2. ПРАВИЛА И ОБЯЗАННОСТИ НАЙМОДАТЕЛЯ.</h3>
      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          2.1. НАЙМОДАТЕЛЬ обязуется:
        </p>
        {contract4.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`2.1.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          2.2. НАЙМОДАТЕЛЬ ИМЕЕТ ПРАВО:
        </p>
        {contract5.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`2.2.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <h3 className={s.subtitle}>3. ПРАВА И ОБЯЗАННОСТИ НАНИМАТЕЛЯ.</h3>
      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          3.1. НАНИМАТЕЛЬ вправе:
        </p>
        {contract6.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`3.1.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>
      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          3.2. НАНИМАТЕЛЬ обязан:
        </p>
        {contract7.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`3.2.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <h3 className={s.subtitle}>4. ОТВЕСТВЕННОСТЬ СТОРОН.</h3>
      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          4.1. Ответственность НАЙМОДАТЕЛЯ:
        </p>
        <p className={s.paragraph}>
          4.1.1. НАЙМОДАТЕЛЬ не несет ответственности за сохранность вещей,
          ценностей и документов НАНИМАТЕЛЯ, оставленных на территории Локации,
          равно как авто и других видов транспорта НАНИМАТЕЛЯ, паркуемых вблизи
          Локации.
        </p>
      </div>
      <div className={s.wrapper}>
        <p className={`${s.paragraph} ${s.lastParagraph}`}>
          4.2. Ответственность НАНИМАТЕЛЯ:
        </p>
        <p className={s.paragraph}>
          4.2.1. НАНИМАТЕЛЬ возмещает НАЙМОДАТЕЛЮ материальный ущерб,
          причиненный в результате невыполнения обязанностей, предусмотренных в
          п. 3.2. настоящего договора, в установленном законом порядке.
        </p>
      </div>

      <h3 className={s.subtitle}>5. УСЛОВИЯ ОПЛАТЫ.</h3>
      <div className={s.wrapper}>
        {contract8.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`5.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <h3 className={s.subtitle}>6. ПРОЧИЕ УСЛОВИЯ.</h3>
      <div className={s.wrapper}>
        {contract9.map((paragraph, i) => (
          <p key={i} className={s.paragraph}>
            {`6.${i + 1}. ${paragraph}`}
          </p>
        ))}
      </div>

      <h3 className={s.subtitle}>7. ДОПОЛНИТЕЛЬНЫЕ УСЛУГИ.</h3>
      <div className={s.wrapper}>
        <p className={s.paragraph}>
          По желанию НАНИМАТЕЛЮ могут быть предоставлены дорлнительные услуги:
          <br />- приготовление шашлыка; <br />- приготовление узбекского плова.
        </p>
        <p>
          В стоимость ополнительных услуг входит: продукты, приготовление и
          подача готовых блюд. СТоимость указывается в п. 1.3.1 Договора.
        </p>
      </div>

      <h3 className={s.subtitle}>8. РЕКВИЗИТЫ И ПОДПИСИ СТОРОН.</h3>
      <div className={s.wrapper}>
        <div className={s.info}>
          <div>
            <p>НАЙМОДАТЕЛЬ:</p>
            <p>ФИО:</p>
            <p>Дата рождения:</p>
            <p>Место рождения:</p>
            <p>Гражданство:</p>
            <p>Паспорт:</p>
            <p>Выдан:</p>
            <p>Адрес регистрации:</p>
            <p>Телефон:</p>
            <p>Подпись:______________</p>
          </div>
          <div>
            <p>НАНИМАТЕЛЬ:</p>
            <p>ФИО:</p>
            <p>Дата рождения:</p>
            <p>Место рождения:</p>
            <p>Гражданство:</p>
            <p>Паспорт:</p>
            <p>Выдан:</p>
            <p>Адрес регистрации:</p>
            <p>Телефон:</p>
            <p>Подпись:______________</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RentalRules;
