import { Link } from "react-router-dom";

import s from "./Button.module.css";

const Button = props => {
  const { text, href, to } = props;

  return (
    <>
      {to ? (
        <Link className={s.btnLink} to={to}>
          <span>{text}</span>
        </Link>
      ) : (
        <a
          href={href}
          className={s.btnAnchor}
          target={"_blank"}
          rel="noreferrer"
        >
          <span>{text}</span>
        </a>
      )}
    </>
  );
};

export default Button;
