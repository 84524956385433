import Layout from "../components/UI/Layout";
import RentalAgreement from "../components/Rental";
import Button from "../components/UI/Button";
import ScrollToTop from "../components/UI/ScrollToTop";
import Footer from "../components/Footer";
import HeaderDocs from "../components/HeaderDocs";

const RentalPage = () => {
  return (
    <section>
      <ScrollToTop />
      <HeaderDocs />
      <Layout>
        <RentalAgreement />
        <Button text="На главную" to="/" />
      </Layout>
      <Footer />
    </section>
  );
};

export default RentalPage;
