import Table from "./Table";
import TableSign from "./TableSign";
import RentalRules from "./RentalRules";

import s from "../Rules/Rules.module.css";

const RentalAgreement = () => {
  return (
    <div className={s.privacy}>
      <h1 className={s.title}>
        Договор посуточного найма <br /> Творческой Локации 'Дом Отпуска'
      </h1>
      <div className={s.sign}>
        <p>
          Село Перевалово, <br /> Тюменский район
        </p>
        <p>'__'_________20__г.</p>
      </div>

      <div className={s.sign}>
        <p className={s.paragraph}>
          Гражданин РФ ______________________, ____________ года рождения,
          паспорт гражданина РФ _______________, выдан ______________________,
          код подразделения: ______________________, зарегистрированный в
          качестве налогоплательщика налога на профессиональный доход, именуемый
          в дальнейшем "НАЙМОДАТЕЛЬ", с одной стороны, и гражданин РФ
          _____________________________________________________________,
          именуемый в дальнейшем "НАНИМАТЕЛЬ", с другой стороны, заключили
          настоящий Договор о нижеследующем:
        </p>
      </div>

      <RentalRules />

      <div className={s.preTable}>
        <p>
          Приложение №1 <br /> к договору посуточного найма <br /> Творческой
          Локации 'Дом Отпуска' <br /> от '__'_________20__г.
        </p>
      </div>
      <h2 className={s.subtitle}>
        ПЕРЕЧЕНЬ И СТОИМОСТЬ ВОЗМЕЩЕНИЯ ПОВРЕЖДЕННОГО ИМУЩЕСТВА
      </h2>
      <Table />
      <TableSign />
    </div>
  );
};

export default RentalAgreement;
